<template>  
  <div class="content-wrapper overflow-visible">
    <div class="page-header page-header-sticky page-header-sticky-open">
        <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">
            <div class="page-header-info flex-1 py-3 text-uppercase">
                <div class="d-flex justify-content-between">
                    <div>
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                <span>{{rowReg.ap_fullname||"-"}}</span>
                                <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                    title="No Rekam Medis">{{rowReg.ap_code||"-"}}</span>
                                <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                    title="No Registrasi">{{rowReg.aur_reg_code||"-"}}</span>
                                <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                    title="No KTP">{{rowReg.ap_nik||"-"}}</span>
                                <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                    title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
                            </h5>
                        </div>
                        <div class="phi-sub-info d-flex">
                            <div class="phi-info d-flex flex-column">
                                <small>Tanggal Lahir</small>
                                <strong
                                    class="font-weight-semibold">{{rowReg.ap_dob | moment("DD MMM YYYY")}}</strong>
                            </div>
                            <div class="phi-info d-flex flex-column pl-2 ml-3">
                                <small>Usia</small>
                                <strong
                                    class="font-weight-semibold">{{rowReg.ap_usia_with_ket || "-"}}<br />({{rowReg.ap_gol_usia||"-"}})</strong>
                            </div>
                            <div class="phi-info d-flex flex-column pl-2 ml-3">
                                <small>Jenis Kelamin</small>
                                <strong class="font-weight-semibold">{{rowReg.cg_label}}</strong>
                            </div>
                            <div class="phi-info d-flex flex-column pl-2 ml-3">
                                <small>Opsi Pembayau</small>
                                <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                            </div>
                            <div class="phi-info d-flex flex-column pl-2 ml-3">
                                <small>Agama</small>
                                <strong class="font-weight-semibold">{{rowReg.ap_agama_text || "-"}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <a href="javascript:;" @click="toDetailKajianPerawatUGD()" data-toggle="modal" data-target="#modalKajianAwalPerawat" class="btn btn-outline-light">Lihat Kajian Perawat</a>
                        <a href="javascript:;" v-if="row.have_rajal" data-toggle="modal" data-target="#modalRiwayatRM" class="btn btn-outline-light mt-1" @click="doOpenRiwayatRMV2(row.kajianRajal)">Lihat Riwayat Rekam Medis Rajal</a>
                        <a href="javascript:;" @click="doOpenRiwayatRMUGD(row)" data-toggle="modal" data-target="#modalRiwayatRM" class="btn btn-outline-light mt-1">Lihat Riwayat Rekam Medis UGD</a>
                    </div>
                </div>
            </div>
        </div>
        <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
        </a>
    </div>

    <div class="content">
      <template v-if="isListDetail">
        <div class="page-title pt-2 pb-3">
          <a href="javascript:;" @click="back()" class="text-dark">
            <i class="icon-arrow-left52 mr-1"></i>
            <span class="font-weight-semibold">Kembali</span>
          </a>
        </div>
        
        <div class="row g-2">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="d-flex align-items-center">
                  <h5 class="card-title font-weight-semibold mr-auto">CPPT Dokter Specialist</h5>
                  <div class="form-group mb-0 mr-1">
                    <button @click="$parent.apiGet()" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
                      class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.left><i class="icon-spinner11"></i></button>
                  </div>
                        
                  <div>
                    <a @click="addNewCPPT('DOKTER')" href="javascript:;" class="btn btn-info btn-labeled btn-labeled-left mr-2">
                      <b><i class="icon-plus2"></i></b>
                      <span>Tambah CPPT Dokter</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Asesmen</th>
                      <th>Instruksi</th>
                      <th>Status</th>
                      <th width="150">Aksi</th>
                    </tr>
                  </thead>
                  <template v-for="(v,k) in (dataCPPT||[])">
                    <tbody v-if="v.aucp_type == 'DOKTER'" :key="k">
                      <tr class="table-info">
                        <td colspan="3">
                          <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center">
                              <div class="font-weight-semibold mr-2">{{v.aucpd_tanggal | moment("DD MMMM YYYY")}}, 
                              {{v.aucpd_jam}}</div>
                              <small class="text-secondary">PPA: {{getUserInput(v.aucp_type)||v.ppas}} - {{v.bu_full_name||"-"}}</small>
                            </div>
                          </div>
                        </td>
                        <td rowspan="5">
                          <span v-if="v.aucp_is_sbar_ugd == 'Y'"> - </span>
                          <template v-else>
                            <template v-if="(v.aucp_created_by == $parent.user.id || v.bu_level == $parent.user.levelId || $parent.user.levelId == 1)">
                            <a href="javascript:;" @click="toEdit(v,'DOKTER')" v-b-tooltip.hover title="Edit CPPT" class="btn btn-icon btn-sm btn-info ml-2"><i class="icon-pencil"></i></a>

                            <a href="javascript:;" @click="deleteCPPT(v)" v-if="v.aucp_is_draft=='Y' && (v.aucp_created_by == $parent.user.id || v.bu_level == $parent.user.levelId || $parent.user.levelId == 1)" v-b-tooltip.hover title="Hapus CPPT" class="btn btn-icon btn-sm btn-danger ml-2"><i class="icon-bin"></i></a>
                            </template>
                            <span v-else> - </span>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-top">
                          <div class="form-row">
                            <div class="col-auto">
                              <div class="label_code label_code_sm m-0">
                                <h2>S</h2>
                              </div>
                            </div>
                            <div class="col">
                              <pre class="pre-input">{{v.aucpd_subjektif||"-"}}</pre>
                            </div>
                          </div>
                        </td>
                        <td class="align-top" rowspan="4">-</td>
                        <td class="align-top" rowspan="4">
                          <template v-if="v.aucp_is_draft == 'Y'">
                            <span class="badge badge-warning">Draft</span>
                          </template>
                          <template v-else>
                            <span class="badge badge-success">Aktif</span>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-top">
                          <div class="form-row">
                            <div class="col-auto">
                              <div class="label_code label_code_sm m-0">
                                <h2>O</h2>
                              </div>
                            </div>
                            <div class="col">
                              <pre class="pre-input">{{v.aucpd_objektif||"-"}}</pre>
                              <p><span class="font-weight-semibold">Status Lokalis:</span> <br/>
                              <ul v-if="(v.aucpd_status_lokalis||[]).length" >
                                <li v-for="(vSl,kSl) in (v.aucpd_status_lokalis||[])" :key="kSl">
                                  Terdapat {{(vSl.data||[]).length}} titik yang ditandai pada bagian {{defineAnat(vSl.type)}} pasien. 
                                </li>
                              </ul>
                              <span v-else> Tidak ada pemeriksaan </span>
                              <a href="javascript:;" @click="toDetailSBAR(v)" data-toggle="modal" class="font-weight-semibold text-underline">Lihat Selengkapnya</a></p>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-top">
                          <div class="form-row">
                            <div class="col-auto">
                              <div class="label_code label_code_sm m-0">
                                <h2>A</h2>
                              </div>
                            </div>
                            <div class="col">
                              <div>
                                <div>
                                  <div class="font-weight-semibold">Diagnosis:</div>   
                                  <ul class="pl-3 mb-0" v-if="(v.diagnosa||[]).length">
                                    <li v-for="(v1,k1) in (v.diagnosa||[])" :key="k1">
                                      {{v1.mi10_name||"-"}}
                                      <span class="font-weight-semibold">[{{v1.mi10_code||"-"}}]</span>
                                    </li>
                                  </ul>
                                </div>
                                <p>Diagnosa Lainnya: 
                                <pre class="pre-input">{{v.aucpd_diagnosa_text||"-"}}</pre>
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-top">
                          <div class="form-row">
                            <div class="col-auto">
                              <div class="label_code label_code_sm m-0">
                                <h2>P</h2>
                              </div>
                            </div>
                            <div class="col">
                              <div>
                                <p>{{v.aucpd_perencanaan||"-"}}</p>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                  <template v-if="!(dataCPPT||[]).length">
                    <tr>
                      <td colspan="99" class="text-center">Tidak ada Data</td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>

      </template>
      <template v-else>
        <FormDokter v-if="typeForm == 'DOKTER'" />
      </template>
    </div>

  
    <b-modal v-model="openDetailKajian" :title="row.arm_is_kajian_awal == 'Y' ? 'Detail Kajian Awal': 'Detail Kajian Lanjutan'" size="xl" hide-footer>
        <DetailKajianAwal v-bind="passToSub" v-if="row.arm_is_kajian_awal == 'Y'" />
        <DetailKajianLanjutan v-bind="passToSubLanjutan" v-else />
    </b-modal>
    
        
    <b-modal v-model="openRwiayatRM" :title="'Detail Rekam Medis'" size="xl" hide-footer>
        <RiwayatRM v-bind="passToSubRM" />
    </b-modal>        
    
    <b-modal v-model="openDetailKajianPerawatUGD" title="Kajian Sekunder" size="xl" hide-footer>
        <KajianPerawatUGD v-bind="passToSubPerawatUGD" /> 
    </b-modal>
    
    <b-modal v-model="openRiwayatRMUGD" title="Detail Riwayat Rekam Medis" size="xl" hide-footer>
        <RiwayatRMUGD v-bind="passToSubRMUGD" />
    </b-modal>

  
    <b-modal v-model="openDetailSBAR" :title="'Detail CPPT Dokter'" size="xl" ok-only ok-title="Tutup">
      <DetailSBAR :rowData="dataSBAR"/>
    </b-modal> 

</div>
</template>


<script>
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'

  const moment = require('moment')

  import DetailKajianAwal from '@/components/RiwayatPOLI/DetailKajianAwal.vue'
  import DetailKajianLanjutan from '@/components/RiwayatPOLI/DetailKajianLanjutan.vue'
  import RiwayatRM from '@/components/RiwayatPOLI/RiwayatRM.vue'

  import RiwayatRMUGD from '@/components/RiwayatUGD/RiwayatRMUGD2.vue'
  import KajianPerawatUGD from '@/components/RiwayatUGD/KajianPerawatUGD2.vue'
  import FormDokter from './FormDokter.vue'
  import DetailSBAR from './DetailSBAR.vue'
  
  export default {
    props: {
        row: Object,
        mrValidation: Object,
        Config: Object,
        rowReg: Object,
        dataCPPT: Array
    },
    data() {
      return {
        openDetailKajian: false,
        openDetailKajianPerawat: false,
        openRwiayatRM: false,
        dataRiwayatRMDokter: {},
        dataRiwayatRMDokterTerapi: {},
        dataRiwayatRMDokterGizi: {},
        dataRiwayatRMFisio: {},
        dataRiwayatRMRadiologi: {},
        dataRiwyatRMPerawat: {},
        dataRiwayatRMLab: {},
        dataRiwyatRMPerawatIS: '',
        historyKajianPerawat: [],
        historyKajianDokter: [],
        historyKajianDokterTerapi: [],
        historyKajianDokterGizi: [],
        historyKajianFisio: [],
        historyKajianRadiologi: [],
        historyKajianLab: [],
        dataRiwayatRMFollowUp: {},
        openDetailKajianPerawatUGD: false,
        openRiwayatRMUGD: false,
        dataKajianPerawat: {},
        dataKajianDokter: {},
        historiKajianPerawat: [],
        historiKajianDokter: [],
        masterKajianPerawat: {},
        masterKajianDokter: {},
        masterTriase: {},
        dataKajianTriase: {},
        historiKajianRadiologi: [],
        historiKajianLab: [],
        historiKajianTriase: [],
        dataKajianRadiologi: {},
        dataKajianLab: {},
        dataKajianFarmasi: [],
        dataUGDReservasi: {},
        activeTabInformasi: 1,    
        resepDokter: [],     
        openPengantar: false,
        pengantarRanap: {},

        dataKajian: [],
        modalConfig: {
          title: '',
          size: 'sm'
        },
        isModal: '',
        rowEdit: {},
        openModal: false,

        patientData: {},
        loading: {
            patientInfo: false,
        },
        
        openDetailSBAR: false,
        dataSBAR: {},
      }
    },
    computed: {
      isListDetail(){ return !this.$route.params.kajianNo },  
      typeForm(){ return this.$route.query.typeForm },
      
      passToSub() {
          return _.assign({
          isAdd: this.isAdd,
          mrValidation: this.mrValidation,
          Config: this.Config,
          row: this.row,
          rowReg: this.rowReg,
          mPekerjaan: this.mPekerjaan,
          mAlatBantu: this.mAlatBantu,
          mSkrininGizi: this.mSkrininGizi,
          mSkrininGiziV2: this.mSkrininGiziV2,
          diagnosa: this.diagnosa,
          mSDKI: this.mSDKI,
          mSLKI: this.mSLKI,
          mSIKI: this.mSIKI,
          mPoli: this.mPoli,
          mDokter: this.mDokter,
          mKesadau: this.mKesadau,
          resepDokter: this.resepDokter,
          historyKajianPerawat: this.historyKajianPerawat
          })
      },
      passToSubLanjutan() {
          return _.assign({
          isAdd: this.isAdd,
          mrValidation: this.mrValidation,
          Config: this.Config,
          row: this.row,
          rowReg: this.rowReg,
          diagnosa: this.diagnosa,
          mSDKI: this.mSDKI,
          mSIKI: this.mSIKI,
          mKesadau: this.mKesadau,
          resepDokter: this.resepDokter,
          historyKajianPerawat: this.historyKajianPerawat
          })
      },
      passToSubRM() {
          return _.assign({
          dataRiwayatRMDokter: this.dataRiwayatRMDokter,
          dataRiwayatRMDokterTerapi: this.dataRiwayatRMDokterTerapi,
          dataRiwayatRMDokterGizi: this.dataRiwayatRMDokterGizi,
          dataRiwayatRMFisio: this.dataRiwayatRMFisio,
          dataRiwayatRMRadiologi: this.dataRiwayatRMRadiologi,
          dataRiwayatRMLab: this.dataRiwayatRMLab,
          dataRiwayatRMFarmasi: this.dataRiwayatRMFarmasi,

          historyKajianPerawat: this.historyKajianPerawat,
          historyKajianDokter: this.historyKajianDokter,
          historyKajianDokterTerapi: this.historyKajianDokterTerapi,
          historyKajianDokterGizi: this.historyKajianDokterGizi,
          historyKajianFisio: this.historyKajianFisio,
          historyKajianRadiologi: this.historyKajianRadiologi,
          historyKajianLab: this.historyKajianLab,

          row: this.row,
          rowReg: this.rowReg,
          dataRiwayatRMFollowUp: this.dataRiwayatRMFollowUp,
          })
      },

      
      passToSubPerawatUGD(){
          return _.assign({
              isAdd:this.isAdd,
              mrValidation:this.mrValidation,
              Config:this.Config,
              row: this.row,
              rowReg: this.rowReg,
              dataKajianPerawat: this.dataKajianPerawat,
              historiKajianPerawat: this.historiKajianPerawat,
              masterKajianPerawat: this.masterKajianPerawat,
          })
      },

      passToSubRMUGD(){
          return _.assign({
              isAdd:this.isAdd,
              mrValidation:this.mrValidation,
              Config:this.Config,
              row: this.row,
              rowReg: this.rowReg,
              dataKajianDokter: this.dataKajianDokter,
              dataKajianTriase: this.dataKajianTriase,
              historiKajianDokter: this.historiKajianDokter,
              masterKajianDokter: this.masterKajianDokter,
              masterTriase: this.masterTriase,
              historiKajianRadiologi: this.historiKajianRadiologi,
              historiKajianLab: this.historiKajianLab,
              historiKajianTriase: this.historiKajianTriase,
              dataKajianRadiologi: this.dataKajianRadiologi,
              dataKajianLab: this.dataKajianLab,
              dataKajianFarmasi: this.dataKajianFarmasi,
              dataUGDReservasi: this.dataUGDReservasi
          })
      },
    },
    components: {
      DetailKajianAwal,
      DetailKajianLanjutan,
      RiwayatRM,
      KajianPerawatUGD,
      RiwayatRMUGD,
      FormDokter,
      DetailSBAR
    },
    methods: {
      addNewCPPT(type){
        this.$parent.loadingOverlay = true
        let data = {
            idPasien : this.rowReg.aur_ap_id,
            regId : this.rowReg.aur_id,
            jenisCPPT: type
        }
        data.type = 'init-cppt'
        Gen.apiRest(
            "/do/"+this.$parent.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
          let resp = res.data
          this.$parent.loadingOverlay = false
          this.$router.push({ name: this.$parent.modulePage, params: {pageSlug: this.rowReg.aur_id, kajianNo : resp.aucp_id }, query: {typeForm: resp.menuCPPT}})
          .catch(()=>{})
        }).catch(err => {
          this.$parent.loadingOverlay = false
          if (err) {
            err.statusType = err.status
            err.status = "error"
            err.title = err.response?.data?.title
            err.message = err.response?.data?.message
            err.messageError = err.message
          }
          this.doSetAlertForm(err)
        })
      },
      toEdit(row,menu){
        this.$router.push({ name: this.$parent.modulePage, params: {pageSlug: this.rowReg.aur_id, kajianNo: row.aucp_id},query: {typeForm: menu}}).catch(()=>{})
      },
      deleteCPPT(v){
        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menghapus data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        }).then(result => {
          if (result.value) {
            let data = {
              type: "delete-cppt",
              id: v.aucp_id
            }
            this.$parent.loadingOverlay = true
            Gen.apiRest(
                "/do/"+this.$parent.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                this.$swal({
                  title: 'Berhasil Menghapus Data',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                this.$parent.loadingOverlay = false
                this.$parent.apiGet()
            })
          }
        })
      },
      back(){
        this.$router.push({name : this.$parent.modulePage}).catch({})
      },
      initSticky() {
        const PageHeaderSticky = document.querySelector('.page-header-sticky')
        if (PageHeaderSticky) {
          const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
          if ($(".page-header-sticky").length && $(".page-header-sticky-toggle").length) {
            PageHeaderToggle.addEventListener('click', function (e) {
              // e.preventDefault()
              PageHeaderSticky.classList.toggle('page-header-sticky-open')
              if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                PageHeaderToggle.querySelector('span').innerText =
                    'Sembunyikan Informasi Pasien'
                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
              } else {
                PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
              }
            })
          }
        }
      },
      getConfigDynamic(master,value){
          let text = ''
          if(value){
              let index = (master||[]).findIndex(x => x.value == value)
              if(index !== -1){
                  text = master[index]['text']
              }
          }
          return text
      },
      doOpenRiwayatRMUGD(v){
          this.$parent.loadingOverlay = true
          
          if(v.kajianDokter){
              Gen.apiRest(
                  "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aur_id, 
              ).then(res=>{
                  this.openRiwayatRMUGD = true    
                  this.dataKajianDokter = res.data
                  this.$parent.loadingOverlay = false
              })

              Gen.apiRest(
                  "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aur_id+'&master=1', 
              ).then(res=>{
                  this.masterKajianDokter = res.data
              })     
          }
          

          if(v.triase_id){                
              Gen.apiRest(
                  "/get/"+'UGDTriase'+'/'+v.triase_id+'?regId='+v.aur_id, 
              ).then(res=>{
                  this.dataKajianTriase = res.data
              })
          }
                    
          Gen.apiRest(
              "/get/"+'UGDTriase'+'/'+'master', 
          ).then(res=>{
              this.masterTriase = res.data
          })

          
          if(v.kajianLab){
              Gen.apiRest(
                  "/get/"+'UGDFormLab'+'/'+this.row.ap_id+'/'+v.kajianLab+'?regId='+v.aur_id, 
              ).then(res=>{
                  this.dataKajianLab = res.data
              })
          }
          
          if(v.kajianRadiologi){
              Gen.apiRest(
                  "/get/"+'UGDFormRadiologi'+'/'+this.row.ap_id+'/'+v.kajianRadiologi+'?regId='+v.aur_id, 
              ).then(res=>{
                  this.dataKajianRadiologi = res.data
              })
          }

          Gen.apiRest(
              "/do/" + 'UGDAssesment', {
                  data: {
                  type: 'get-obat-farmasi',
                  regId: v.aur_id
                  }
              }
          ).then(res => {
              let resp = res.data
              this.dataKajianFarmasi = resp.data
              this.dataUGDReservasi = resp.dataUGDReservasi
              this.$parent.loadingOverlay = false
          })
          
          
          this.openRiwayatRMUGD = true
      },
      toDetailKajianPerawatUGD(){
          this.$parent.loadingOverlay = true
          Gen.apiRest(
              "/get/"+'UGDFormPerawat'+'/'+this.row.ap_id+'/'+this.row.kajianPerawat+'?regId='+this.row.aur_id, 
          ).then(res=>{
              this.openDetailKajianPerawatUGD = true    
              this.dataKajianPerawat = res.data
              this.$parent.loadingOverlay = false
          })
          Gen.apiRest(
              "/get/"+'UGDFormPerawat'+'/'+this.row.ap_id+'/'+this.row.kajianPerawat+'?regId='+this.row.aur_id+'&master=1', 
          ).then(res=>{
              this.masterKajianPerawat = res.data
          })
      },
      doOpenRiwayatRMV2(v) {
          this.$parent.loadingOverlay = true
          if (v.kajianDokter) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianDokter + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMDokter = res.data
              this.dataRiwayatRMDokter.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMDokter = {}
          }

          if (v.kajianDokterTerapi) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianDokterTerapi + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMDokterTerapi = res.data
              this.dataRiwayatRMDokterTerapi.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMDokterTerapi = {}
          }

          if (v.kajianGizi) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianGizi + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMDokterGizi = res.data
              this.dataRiwayatRMDokterGizi.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMDokterGizi = {}
          }

          if (v.kajianFisio) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianFisio + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMFisio = res.data
              this.dataRiwayatRMFisio.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMFisio = {}
          }

          if (v.kajianRadiologi) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianRadiologi + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMRadiologi = res.data
              this.dataRiwayatRMRadiologi.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMRadiologi = {}
          }

          if (v.kajianLab) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianLab + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMLab = res.data
              this.dataRiwayatRMLab.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMLab = {}
          }

          Gen.apiRest(
          "/do/" + 'RekamMedis', {
              data: {
              type: 'get-obat-farmasi',
              regId: v.ar_id
              }
          }
          ).then(res => {
          let resp = res.data
          this.dataRiwayatRMFarmasi = resp.data
          this.$parent.loadingOverlay = false
          })

          Gen.apiRest(
          "/do/" + 'RekamMedis', {
              data: {
              type: 'get-riwayat-rekam-medis',
              ar_id: v.ar_id
              }
          }
          ).then(res => {
          let resp = res.data
          // non ppa
          this.historyKajianPerawat = resp.historyKajianPerawat
          this.historyKajianDokter = resp.historyKajianDokter
          this.historyKajianDokterTerapi = resp.historyKajianDokterTerapi
          
          this.$parent.loadingOverlay = false
          })

          Gen.apiRest(
          "/do/" + 'RekamMedis', {
              data: {
              type: 'get-riwayat-rekam-medis',
              ar_id: v.ar_id
              }
          }
          ).then(res => {
          let resp = res.data
          this.$parent.loadingOverlay = false
          
          this.historyKajianDokterGizi = resp.historyKajianDokterGizi
          this.historyKajianFisio = resp.historyKajianFisio
          this.historyKajianRadiologi = resp.historyKajianRadiologi
          this.historyKajianLab = resp.historyKajianLab
          })


          Gen.apiRest(
          "/get/" + 'FollowUp' + '/' + this.row.ap_id + '?regId=' + v.ar_id,
          ).then(res => {
          this.$parent.loadingOverlay = false
          this.dataRiwayatRMFollowUp = res.data
          this.dataRiwayatRMFollowUp.Config = this.Config
          this.openRwiayatRM = true
          })


          setTimeout(() => {
          this.$parent.loadingOverlay = false
          this.openRwiayatRM = true
          }, 2000)
      },
      toValidate(val) {
          return {
              ...val
          }
      },
      
      getUserInput(type){
        let text = ''
        if(type == 'DOKTER'){
          text = 'Dokter'
        }else if(type == 'PPALAB'){
          text = 'Laboratorium'
        }else if(type == 'PPARADIO'){
          text = 'Radiologi'
        }else if(type == 'PPAFISIO'){
          text = 'Fisioterapi'
        }else if(type == 'PPAFARMASI'){
          text = 'Farmasi'
        }else if(type == 'DIETISIEN'){
          text = 'Dietisien'
        }else if(type == 'PERAWAT'){
          text = 'Perawat'
        }else {
          text = null
        }
        return text
      },
      
      defineAnat(anat){
        if(anat == 'eye'){
          return "Mata"
        }else if(anat == 'front-eye'){
          return "Mata Depan"
        }else if(anat == 'odontogram'){
          return "Odontogram"
        }else if(anat == 'nervousSystem'){
          return "Syaraf"
        }else if(anat == 'heart'){
          return "Jantung"
        }else if(anat == 'ear'){
          return "Telinga"
        }else if(anat == 'nose'){
          return "Hidung"
        }else if(anat == 'throat'){
          return "Tenggorokkan"
        }else if(anat == 'lung'){
          return "Paru-Paru"
        }else if(anat == 'joint'){
          return "Sendi"
        }else{
          return "Tubuh"
        }
      },
      
      toDetailSBAR(v){
        let data = {
          type: "detail-sbar",
          id: v.aucp_id
        }
        this.$parent.loadingOverlay = true
        Gen.apiRest(
            "/do/"+'UGDCPPTDokterSpesialist',
            {data: data}, 
            "POST"
        ).then(res=>{
          this.openDetailSBAR = true        
          this.$parent.loadingOverlay = false
          this.dataSBAR = res.data
        })
      },
    },
    mounted() {
      setTimeout(() => {
        this.initSticky()
      }, 1000)
      this.$parent.apiGet()
    },
    // watch:{
    //   $route(){
    //     this.$parent.apiGet()
    //   }
    // }
  }

</script>