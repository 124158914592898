<template>
  <div>
    <div class="card">
      <div class="card-header bg-white">
        <div>
          <div class="label_code">
            <h2>S</h2>
          </div>
          <span class="ml-2">Subjective</span>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Subjektif</h4>
                  <pre class="pre-input">{{rowSBAR.aucpd_subjektif||"-"}}</pre>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card">
      <div class="card-header bg-white">
        <div>
          <div class="label_code">
            <h2>O</h2>
          </div>
          <span class="ml-2">Objective</span>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Objektif</h4>
                  <pre class="pre-input">{{rowSBAR.aucpd_objektif||"-"}}</pre>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="card mb-0">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Status Lokalis</h6>
          </div>
          <div class="card-body p-3">
            <div class="row gx-2" v-if="(rowSBAR.aucpd_status_lokalis||[]).length">
              <div class="col-lg-4">
                <ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar flex-column">
                  <li class="nav-item" @click="changeTabAnat(k)" v-for="(v,k) in rowSBAR.aucpd_status_lokalis||[]" :key="k+'nav'">
                    <a href="javascript:;" data-toggle="tab" 
                    :class="activeAnat == k ? 'nav-link active':'nav-link'">
                      <div class="d-flex justify-content-between align-items-center">
                        <span>{{v.name||"(Anatomi Baru)"}}</span>
                        <div>
                          <i class="icon-chevron-right"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-8">
                <div class="tab-content">
                  <div v-for="(v,k) in rowSBAR.aucpd_status_lokalis||[]" :key="k+'navAnat'" :class="activeAnat == k ? 'tab-pane fade-show active' : 'tab-pane fade'">
                    <div class="wrap_anatomy_info p-0 border-0" v-if="v.type">
                      <div class="position-relative" :id="`anatomiNyeri${k}`" @click.stop.prevent>
                        <div class="body_wrapper">
                          <img :src="assetLocal(v.image)" class="img-fluid w-100" alt="">
                        </div>
                        <div class="anatomy-info" v-for="(v1,k1) in v.data||[]" :key="k1+'body'"
                          :style="`top:${v1.y}%;left:${v1.x}%`">
                          <a href="javascript:;" class="ai-point" v-b-tooltip.hover :html="true"
                            :title="v1.value"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row gx-2" v-else>
              <div class="col-lg-12">
                <p class="text-center">Tidak ada Pemeriksaan</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header bg-white">
        <div>
          <div class="label_code">
            <h2>A</h2>
          </div>
          <span class="ml-2">Assessment</span>
        </div>
      </div>
      <div class="card-body">
        <div class="card border shadow-0">
          <div class="card-header py-2 bg-light">
            <h6 class="card-title font-weight-semibold mb-0">Diagnosa & Tindakan</h6>
          </div>

          <div class="card-body">
              <table class="table table-bordered">
              <tbody>
                  <tr v-for="(v,k) in dataICD10" :key="k+'diagnosaDok2'">
                  <td>
                      <div class="result_tab" v-if="!v.mi10_code">
                      <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                      <p>{{v.auicd10d_diagnosis||"-"}}</p>
                      </div>
                      <div class="result_tab" v-else>
                      <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                      <p>{{v.text||"-"}}</p>
                      </div>
                  </td>
                  </tr>
                  <tr>
                      <td>
                        <div class="result_tab">
                        <h4>Diagnosa Lainnya</h4>
                        <pre class="pre-input">{{rowSBAR.aucpd_diagnosa_text||"-"}}</pre>
                        </div>
                      </td>
                  </tr>
              </tbody>
              </table>
          </div>

          <div class="card-body">
              <table class="table table-bordered">
              <tbody>
                  <tr v-for="(v,k) in dataICD9" :key="k+'diagnosaDok'">
                  <td>
                      <div class="result_tab" v-if="!v.mi9_code">
                      <h4>Tindakan #{{k+1}} (ICD9)</h4>
                      <p>{{v.auicd9d_tindakan||"-"}}</p>
                      </div>
                      <div class="result_tab" v-else>
                      <h4>Tindakan #{{k+1}} (ICD9)</h4>
                      <p>{{v.text||"-"}}</p>
                      </div>
                  </td>
                  </tr>
                  <tr>
                      <td>
                        <div class="result_tab">
                        <h4>Tindakan Lainnya</h4>
                        <pre class="pre-input">{{rowSBAR.aucpd_tindakan_text||"-"}}</pre>
                        </div>
                      </td>
                  </tr>
              </tbody>
              </table>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header bg-white">
        <div>
          <div class="label_code">
            <h2>P</h2>
          </div>
          <span class="ml-2">Planning</span>
        </div>
      </div>
      <div class="card-body">
        <div class="mb-3">
          <table class="table table-bordered table-sm">
            <tr>
              <td class="align-top" width="36%">
                <div class="result_tab">
                  <h4>Perencanaan</h4>
                  <pre class="pre-input">{{rowSBAR.aucpd_perencanaan||"-"}}</pre>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
        <table class="table table-bordered table-sm table-input">
        <thead>
            <tr>
            <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan / ICD-9-CM</h4>
                    <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
                </div>
            </td>
            </tr>
            <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
            {{rowIC.aranic_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
            {{rowIC.aranic_dasar_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
            {{rowIC.aranic_tindakan_dokter||"-"}}
            </td>
            </tr>
            <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
            {{rowIC.aranic_indikasi_tindakan||"-"}}
            </td>
            </tr>
            <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
            {{rowIC.aranic_tata_cara||"-"}}
            </td>
            </tr>
            <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
            {{rowIC.aranic_tujuan||"-"}}
            </td>
            </tr>
            <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
            {{rowIC.aranic_risiko||"-"}}
            </td>
            </tr>
            <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
            {{rowIC.aranic_kompilasi||"-"}}
            </td>
            </tr>
            <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
            {{rowIC.aranic_prognosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
            {{rowIC.aranic_alternatif||"-"}}
            </td>
            </tr>
            <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
            {{rowIC.aranic_lainnya||"-"}}
            </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
            <td width="50%" colspan="2">
                <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
                </div>
            </td>
            <td width="50%">
                <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
                </div>
            </td>
            </tr>
        </tfoot>
        </table>
    </b-modal>
  </div>
</template>

<script>
import Config from '@/libs/Config'
export default {
  props: {
    rowData: Object,
  },
  data(){
    return {
      activeAnat: 0,
      openModalICDetail: false,
      rowIC: {},
    }
  },
  computed: {
    rowSBAR(){
      return this.rowData.rowData
    },
    dataICD9(){
      return this.rowData.dataICD9
    },
    dataICD10(){
      return this.rowData.dataICD10
    },
    Config(){ return Config },
  },
  methods: {  
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    changeTabAnat(k){
      this.activeAnat = k
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },

  }

}
</script>